<template>
  <div class="card">
    <div class="card-header">
      <h6 class="font-weight-light">Por Classificação</h6>
    </div>
    <div class="card-body">
      <v-chart class="chart" :option="optionReder" />
    </div>
  </div>
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'

use([
  CanvasRenderer,
  GridComponent,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
])

export default {
  name: 'ValueByClassification',

  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  components: {
    VChart,
  },

  data() {
    return {
      optionReder: null,
      xEntries: [],
      xExits: [],
      xAxisItems: [],
    }
  },

  methods: {
    generateOptionToChart() {
      const xAxisLabel = this.option.map((item) => item.classification)

      xAxisLabel.forEach((item) => {
        this.xAxisItems.push({
          value: item,
          textStyle: {
            fontSize: 12,
            overflow: 'break',
            ellipsis: '...',
          },
        })
      })
      this.xEntries = this.option.map((item) => item.entries)
      this.xExits = this.option.map((item) => item.exits)
      this.optionReder = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          data: ['Entradas', 'Saídas'],
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: true },
            z: 3,
            axisLabel: {
              interval: 0,
              rotate: 90,
              overflow: 'break',
              ellipsis: '...',
              inside: true,
              height: 20,
              borderColor: '#000',
              color: '#FFF',
              padding: 5,
              backgroundColor: 'rgba(0,0,0,0.5)',
            },
            data: this.xAxisItems,
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: 'Entradas',
            type: 'bar',
            barGap: 0,
            itemStyle: {
              color: '#2ecc71',
            },
            emphasis: {
              focus: 'series',
            },
            data: this.xEntries,
          },
          {
            name: 'Saídas',
            type: 'bar',
            itemStyle: {
              color: '#F72C5B',
            },
            emphasis: {
              focus: 'series',
            },
            data: this.xExits,
          },
        ],
      }
    },
  },

  mounted() {
    this.generateOptionToChart()
  },
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
