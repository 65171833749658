<template>
  <div
    class="card"
    :class="doFilter ? 'cursor-pointer' : ''"
    @click="filterNow"
  >
    <div class="card-body text-center">
      <i :class="`${item.icon} ph-lg text-${item.color}`"></i>
      <h5 class="mb-1">{{ item.title }}</h5>
      <h3>{{ formatToCurrencyBrLabel(item.value) }}</h3>
    </div>
  </div>
</template>
<script>
import { formatToCurrencyBrLabel } from '@/utils/currency'

export default {
  name: 'CardState',
  props: {
    item: {
      type: Object,
      required: true,
    },
    doFilter: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      formatToCurrencyBrLabel,
    }
  },
  methods: {
    filterNow() {
      if (!this.doFilter) return
      this.$emit('filterNow', this.item)
    },
  },
}
</script>
