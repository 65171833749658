<template>
  <DeleteSimulationModal
    v-if="showModal"
    :simulation="selectedSimulation"
    @closeModal="closeModal"
  />
  <div class="card">
    <div class="card-header">
      <h6 class="fw-lighat mb-0">Dados Simulados</h6>
    </div>
    <div class="table-responsive">
      <table class="table table-sm table-striped table-scrollable">
        <thead>
          <tr>
            <th scope="col">Data</th>
            <th scope="col">Classificação</th>
            <th scope="col">Valor</th>
            <th scope="col">Histórico</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in simulationData" :key="index">
            <td>{{ dateTimeSqlToDateBR(item.date) }}</td>
            <td>{{ item.classification }}</td>
            <td>{{ formatToCurrencyBrLabel(item.netValue) }}</td>
            <td>{{ item.historic }}</td>
            <td class="p-1">
              <button
                class="btn btn-sm btn-flat-danger p-1"
                @click="deleteSimulation(item)"
              >
                <i class="ph-trash-simple"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import DeleteSimulationModal from '@/components/modals/DeleteSimulationModal.vue'
import { dateTimeSqlToDateBR } from '@/utils/date'
import { entryTypeLabel } from '@/utils/status'
import { formatToCurrencyBrLabel } from '@/utils/currency'

export default {
  name: 'SimulationTable',
  props: {
    simulationData: {
      type: Array,
      required: true,
    },
  },
  components: {
    DeleteSimulationModal,
  },
  data() {
    return {
      dateTimeSqlToDateBR,
      entryTypeLabel,
      formatToCurrencyBrLabel,
      selectedSimulation: null,
      showModal: false,
    }
  },

  methods: {
    deleteSimulation(item) {
      this.selectedSimulation = item
      this.showModal = true
    },
    closeModal(needReload) {
      this.showModal = false
      if (needReload) {
        this.$emit('searchData')
      }
    },
  },
}
</script>
