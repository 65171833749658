<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-4">
          <label class="form-label">Unidade</label>
          <div class="input-group">
            <select class="form-select" v-model="filter.company">
              <option value="1" selected>Selecione uma unidade</option>
              <option
                v-for="company in companies"
                :key="company.codColigada"
                :value="company"
              >
                {{ company.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <label class="form-label">Filial</label>
          <div class="input-group">
            <select class="form-select" v-model="filter.filial">
              <option value="0" selected>Selecione uma filial</option>
              <option
                v-for="filial in filials"
                :key="filial.codFilial"
                :value="filial"
              >
                {{ filial.nomeFilial }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-2">
          <label class="form-label">Data Inicial:</label>
          <div class="input-group">
            <input
              type="date"
              class="form-control"
              v-model="filter.startDate"
            />
          </div>
        </div>
        <div class="col-12 col-md-2">
          <label class="form-label">Data Final:</label>
          <div class="input-group">
            <input type="date" class="form-control" v-model="filter.endDate" />
          </div>
        </div>
        <div class="col-12 col-md-1 pt-3">
          <button
            class="btn btn-primary w-100 mt-2"
            :disabled="disabledFilterSearch"
            @click="searchData"
          >
            <i class="ph-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { transformDateInObject, dateNowUs } from '@/utils/date'

export default {
  name: 'FilterCard',
  props: {
    companies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      disabledFilterSearch: true,
      transformDateInObject,
      filials: [],
      filter: {
        company: null,
        filial: null,
        startDate: null,
        endDate: null,
      },
    }
  },
  watch: {
    'filter.company': {
      handler(newValue, oldValue) {
        if (newValue && newValue.codColigada) {
          if (
            oldValue == null ||
            newValue.codColigada !== oldValue.codColigada
          ) {
            this.getFilials()
          }
        }
      },
      immediate: true,
    },
    'filter': {
      handler(newValue, oldValue) {
        this.disabledFilterSearch = !(
          this.filter.company &&
          this.filter.filial &&
          this.filter.startDate &&
          this.filter.endDate
        )

        const ntToday = new Date()
        const ntStartDate = new Date(this.filter.startDate)
        const ntEndDate = new Date(this.filter.endDate)
        if (ntStartDate > ntToday) {
          this.$root.$refs.notification.warning(
            'A data inicial não pode ser maior que hoje, data ajustada para hoje',
          )
          this.filter.startDate = dateNowUs()
        }
        if (this.filter.endDate && ntStartDate > ntEndDate) {
          this.$root.$refs.notification.warning(
            'A data inicial não pode ser maior que a data final.',
          )
          this.disabledFilterSearch = true
        }
      },
      deep: true,
    },
  },
  methods: {
    searchData() {
      this.$emit('searchData', this.filter)
    },
    async getFilials() {
      try {
        this.isLoadingFilials = true
        this.filials = await this.$store.dispatch(
          'getFilials',
          this.filter.company.codColigada,
        )
        this.isLoadingFilials = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
}
</script>
