<template>
  <ul class="nav nav-pills nav-justified mb-3" role="tablist">
    <li class="nav-item" role="presentation">
      <a
        href="#tab-dashboard"
        class="nav-link rounded-pill active"
        data-bs-toggle="tab"
        aria-selected="true"
        role="tab"
        >Dashboard</a
      >
    </li>
    <li class="nav-item" role="presentation">
      <a
        href="#tab-simulation"
        class="nav-link rounded-pill"
        data-bs-toggle="tab"
        aria-selected="true"
        role="tab"
        >Simulação</a
      >
    </li>
  </ul>
  <div class="tab-content card-body">
    <div class="tab-pane fade active show" id="tab-dashboard" role="tabpanel">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h6 class="fw-lighat mb-0">Indicador</h6>
            </div>
            <div class="table-responsive">
              <iframe
                title="Dashboard Financeiro"
                style="width: 100%"
                height="700"
                src="https://app.powerbi.com/view?r=eyJrIjoiNmY0OWYzMzQtMmExOC00MjYxLWEyOGQtM2EzYzI3ZmMwMWZhIiwidCI6Ijk0ZmNmNjUzLThkZDItNDFiNC05ZThjLWRhNDJhMjA3YjhhMCJ9"
                frameborder="0"
                allowFullScreen="true"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-pane fade" id="tab-simulation" role="tabpanel">
      <AddOrEditSimulationModal
        v-if="showModal"
        :company="filter.company"
        :filial="filter.filial"
        @closeModal="closeModal"
      ></AddOrEditSimulationModal>
      <FilterCard :companies="companies" @searchData="setFilter"></FilterCard>
      <div v-if="isLoadingData" class="row">
        <div class="col-12 text-center">
          <h3 class="fw-light">Atenção</h3>
          <h6>Selecione um período para buscar</h6>
        </div>
      </div>
      <div class="" v-else>
        <div v-if="isSearching" class="row">
          <div class="col-12 text-center">
            <h3 class="fw-light">Aguarde...</h3>
            <h6>Carregando dados...</h6>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <CardState :item="initialBalanceData" :doFilter="false"></CardState>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <CardState
              :item="inflowData"
              :doFilter="true"
              @filterNow="filterNow"
            ></CardState>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <CardState
              :item="outflowData"
              :doFilter="true"
              @filterNow="filterNow"
            ></CardState>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <CardState :item="finalBalanceData" :doFilter="false"></CardState>
          </div>
          <div class="col-12"></div>
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-sm-5">
                    <label for="filterClassification"
                      >Filtrar Classificação:</label
                    >
                    <select
                      id="filterClassification"
                      v-model="filterClassification"
                      class="form-select"
                      @change="filterGlobal"
                    >
                      <option value="">Exibir todas as classificações</option>
                      <option
                        v-for="(classification, index) in classifications"
                        :key="index"
                        :value="classification.classification"
                      >
                        {{ classification.classification }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-sm-5">
                    <label for="filterType">Filtrar Tipo:</label>
                    <select
                      id="filterType"
                      v-model="filterTypes"
                      class="form-select"
                      @change="filterGlobal"
                    >
                      <option value="" selected>Exibir todas os Tipos</option>
                      <option
                        v-for="(types, index) in types"
                        :key="index"
                        :value="types"
                      >
                        {{ types }}
                      </option>
                    </select>
                  </div>
                  <div class="col-12 col-sm-2">
                    <button
                      class="btn btn-primary w-100 mt-3"
                      @click="addSimulation"
                    >
                      Adicionar Simulação
                    </button>
                  </div>
                </div>
              </div>
              <StatementsTable :statements="statementsData"></StatementsTable>
            </div>
          </div>

          <div class="col-12">
            <SimulationTable
              :simulationData="simulationData"
              @searchData="searchData"
            />
          </div>
          <div class="col-12">
            <ValueByClassification
              :option="classifications"
            ></ValueByClassification>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FilterCard from '@/components/inputs/FilterCard.vue'
import StatementsTable from '@/components/tables/StatementsTable.vue'
import CardState from '@/components/cards/CardState.vue'
import ValueByClassification from '@/components/charts/ValueByClassification.vue'
import AddOrEditSimulationModal from '@/components/modals/AddOrEditSimulationModal.vue'
import SimulationTable from '@/components/tables/SimulationTable.vue'

export default {
  name: 'Home',
  data() {
    return {
      showModal: false,
      isLoadingCompanies: true,
      isSearching: true,
      isLoadingData: true,
      isLoadingFilials: true,
      companies: [],
      balance: [],
      statements: [],
      statementsData: [],
      simulationData: [],
      filterClassification: '',
      filterTypes: '',
      classifications: [],
      filter: {
        company: null,
        filial: null,
        startDate: null,
        endDate: null,
      },
      internalFilter: null,
      internalFilterEntries: null,
      inflow: 0,
      outflow: 0,
      initialBalanceData: {
        title: 'Saldo Inicial',
        value: 0,
        icon: 'ph-plus',
        color: 'info',
      },
      inflowData: {
        title: 'Entradas',
        value: this.inflow,
        icon: 'ph-arrow-up',
        color: 'success',
      },
      outflowData: {
        title: 'Saídas',
        value: this.outflow,
        icon: 'ph-arrow-down',
        color: 'danger',
      },
      finalBalanceData: {
        title: 'Saldo Final',
        value: 0,
        icon: 'ph-equals',
        color: 'purple',
      },
    }
  },
  components: {
    FilterCard,
    StatementsTable,
    SimulationTable,
    CardState,
    ValueByClassification,
    AddOrEditSimulationModal,
  },
  methods: {
    closeModal(needReload) {
      this.showModal = false
      if (needReload) {
        this.searchData()
      }
    },
    async addSimulation() {
      this.showModal = true
    },
    async getCompanies() {
      try {
        this.isLoadingCompanies = true
        this.companies = await this.$store.dispatch('getCompanies')
        this.isLoadingCompanies = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },

    setFilter(filter) {
      this.filter = filter
      this.searchData()
    },
    async searchData() {
      try {
        this.inflow = 0
        this.outflow = 0
        this.isLoadingData = false
        this.isSearching = true
        // Extrato Financeiro
        const statementsrResponse = await this.$store.dispatch(
          'getStatements',
          {
            codColigada: this.filter.company.codColigada,
            codFilial: this.filter.filial.codFilial ?? 1,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
          },
        )
        this.statements = statementsrResponse.statements
        this.classifications = statementsrResponse.classifications
        this.types = statementsrResponse.types
        this.simulationData = this.statements.filter(
          (statement) => statement.entryType === 'SIMULAÇÃO',
        )
        this.statements.forEach((statement) => {
          if (statement.netValue > 0) {
            this.inflow += statement.netValue
          } else {
            this.outflow += statement.netValue
          }
        })
        this.inflowData.value = this.inflow
        this.outflowData.value = this.outflow
        // Saldo Inicial
        this.initialBalanceData.value = await this.$store.dispatch(
          'getBalance',
          {
            codColigada: this.filter.company.codColigada,
            codFilial: this.filter.filial.codFilial,
            startDate: this.filter.startDate,
          },
        )
        // Saldo Final
        this.finalBalanceData.value =
          this.initialBalanceData.value + this.inflow + this.outflow
        this.statementsData = this.statements
        this.isSearching = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    filterNow(filter) {
      if (this.internalFilter !== filter.title) {
        this.internalFilter = filter.title
      } else {
        this.internalFilter = null
      }
      this.filterGlobal()
    },
    filterGlobal() {
      this.isSearching = true
      this.statementsData = this.statements
      // Filtrar Entradas e Saidas
      if (this.internalFilter) {
        this.statementsData = this.statements.filter((statement) => {
          if (this.internalFilter === 'Entradas') {
            return statement.netValue > 0
          }
          if (this.internalFilter === 'Saídas') {
            return statement.netValue < 0
          }
          return null
        })
      }
      // Filtrar Classificação
      if (this.filterClassification !== '') {
        this.statementsData = this.statementsData.filter((statement) => {
          return statement.classification === this.filterClassification
        })
      }
      // Filtrar Classificação
      if (this.filterTypes !== '') {
        this.statementsData = this.statementsData.filter((statement) => {
          return statement.entryType === this.filterTypes
        })
      }
      this.isSearching = false
      return null
    },
  },

  mounted() {
    this.getCompanies()
  },
}
</script>
