<template>
  <div id="deleteModal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Deletar Simulação</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            title="Fechar"
            @click="closeModal(false)"
          />
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <p class="">Tem certeza que deseja deletar a simulação?</p>
              <p>{{ simulation.classification }}</p>
              <p class="text-danger">
                <strong>Atenção:</strong> Essa ação não poderá ser desfeita.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer border-top-0">
          <button
            type="button"
            class="btn btn-link"
            data-bs-dismiss="modal"
            title="Cancelar"
            @click="closeModal(false)"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            title="Atualizar"
            :disabled="disableButton"
            @click="deleteSimulation"
          >
            <span v-if="disableButton">
              <i class="ph-spinner spinner" />
            </span>
            <span v-else>Deletar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteSimulationModal',
  props: {
    simulation: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalInstance: null,
      disableButton: false,
    }
  },

  methods: {
    async deleteSimulation() {
      try {
        this.disableButton = true
        const response = await this.$store.dispatch(
          'deleteSimulation',
          this.simulation,
        )
        this.$root.$refs.notification.success(response.message)
        this.closeModal(true)
        this.disableButton = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    closeModal(reload) {
      this.modalInstance.hide()
      this.$emit('closeModal', reload)
    },
    showModal() {
      this.modalInstance = new window.bootstrap.Modal(
        document.getElementById('deleteModal'),
        {
          backdrop: 'static',
          keyboard: false,
        },
      )
      this.modalInstance.show()
    },
  },

  mounted() {
    this.showModal()
  },
}
</script>
