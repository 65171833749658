<template>
  <div class="card-body p-0">
    <v-grid
      theme="compact"
      :source="statements"
      :columns="columns"
      :grouping="grouping"
      :filter="filterNames"
      :columnTypes="columnTypes"
      autoSizeColumn="true"
      resize="true"
      exporting="true"
    ></v-grid>
  </div>
</template>
<style>
revo-grid {
  height: 450px;
}
</style>
<script>
import VGrid, { VGridVueTemplate } from '@revolist/vue3-datagrid'
import NumberColumnType from '@revolist/revogrid-column-numeral' // import library
import { dateTimeSqlToDateBR } from '@/utils/date'
import { entryTypeLabel } from '@/utils/status'
import { formatToCurrencyBrLabel } from '@/utils/currency'

export default {
  name: 'StatementsTable',
  props: {
    statements: {
      type: Array,
      required: true,
    },
  },
  components: {
    VGrid,
  },
  data() {
    return {
      dateTimeSqlToDateBR,
      entryTypeLabel,
      formatToCurrencyBrLabel,
      columnTypes: {
        numeric: new NumberColumnType('0.00'),
      },
      filter: true,
      grouping: {
        // props: ['date'],
        // expandedAll: true,
      },

      filterNames: {
        none: 'Nenhum',
        empty: 'Vazio',
        notEmpty: 'Preenchido',

        eq: 'Igual',
        notEq: 'Diferente',
        begins: 'Começa com',
        contains: 'Contem',
        notContains: 'Não Contem',

        eqN: '=',
        neqN: '!=',
        gt: '>',
        gte: '>=',
        lt: '<',
        lte: '<=',
      },
      columns: [
        {
          prop: 'date',
          name: 'Data',
          columnType: 'date',
          sortable: true,
          filter: true,
          order: 'asc',
          autoSize: true,
          size: 120,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            // return entryTypeLabel(props.model[props.prop])
            return createElement(
              'span',
              {
                class: '',
              },
              dateTimeSqlToDateBR(props.model[props.prop]),
            )
          },
        },
        {
          prop: 'classification',
          name: 'Classificação',
          order: 'asc',
          sortable: true,
          autoSize: true,
          size: 400,
          pin: 'colPinStart',
        },
        {
          prop: 'entryType',
          name: 'Tipo',
          sortable: true,
          order: 'asc',
          size: 150,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            // return entryTypeLabel(props.model[props.prop])
            if (props.model[props.prop] === 'REALIZADO') {
              return createElement(
                'span',
                {
                  class: 'badge bg-success',
                },
                'Realizado',
              )
            }
            if (props.model[props.prop] === 'LANCAMENTO FUTURO') {
              return createElement(
                'span',
                {
                  class: 'badge bg-yellow text-dark',
                },
                'Lançamento Futuro',
              )
            }
            if (props.model[props.prop] === 'SIMULAÇÃO') {
              return createElement(
                'span',
                {
                  class: 'badge bg-info ',
                },
                'Simulação',
              )
            }

            return createElement(
              'span',
              {
                style: {
                  color: 'red',
                },
              },
              entryTypeLabel(props.model[props.prop]),
            )
          },
        },
        {
          prop: 'netValue',
          name: 'Valor',
          pin: 'colPinStart',
          columnType: 'numeric',
          size: 150,
          filter: 'number',
          cellTemplate: (createElement, props) => {
            // return entryTypeLabel(props.model[props.prop])
            return createElement(
              'span',
              {
                class:
                  props.model[props.prop] < 0 ? 'text-danger' : 'text-success',
              },
              formatToCurrencyBrLabel(props.model[props.prop]),
            )
          },
        },
        {
          prop: 'historic',
          name: 'Histórico',
          sortable: true,
          order: 'asc',
          autoSizeColumn: true,
        },
      ],
    }
  },
  watch: {
    filter: {
      handler() {
        this.disabledFilterSearch = !(
          this.filter.company &&
          this.filter.startDate &&
          this.filter.endDate
        )
        console.log(Date(this.filter.startDate), Date(this.filter.endDate))
        const ntStartDate = new Date(this.filter.startDate)
        const ntEndDate = new Date(this.filter.endDate)
        if (this.filter.endDate && ntStartDate > ntEndDate) {
          this.$root.$refs.notification.warning(
            'A data inicial não pode ser maior que a data final.',
          )
          this.disabledFilterSearch = true
        }
      },
      deep: true,
    },
  },
  methods: {
    searchData() {},
  },
}
</script>
